import 'appstart';

//import 'SCSSEntries/frontPage.scss';
import 'Vendors/knockout/knockout-pack';
import 'Utils/mere.js';

import(/* webpackChunkName: "widgets-module" */ 'Widgets/widgetMoreItems.js');
import(/* webpackChunkName: "searchbar-module" */ 'searchbar');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/pageIntro.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/banner-cards.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationsSlider.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/frontpageHotelThemes.js');



/* 
// FIXME !!! legacy script :: Add this if 'frontpageExpandText' is used on FrontPage 
function frontpageExpandText() {
	$('.front-expandtext .c').slideToggle(500);
	if ($('.front-expandtext .c').is(':visible')) {
		$('html, body').animate({ scrollTop: $('.front-expandtext').offset().top - 200 }, 1300);
	}

};
global.frontpageExpandText = frontpageExpandText;
*/

//AppLoader.setReady();